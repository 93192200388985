<template>
  <div>
    <b-card>
      <div>
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >

            <!-- 预报单号 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="预报单号"
                label-for="number"
              >
                <b-form-input
                  id="number"
                  type="text"
                  placeholder="Vuexy Admin Template"
                />
              </b-form-group>
            </b-col>

            <!-- 目的地/收件人 -->
            <b-col
              xl="2"
              lg="2"
              md="2"
              sm="12"
            >
              <b-form-group
                label="目的地/收件人"
                label-for="recipient"
              >
                <b-form-input
                  id="recipient"
                  disabled
                  type="number"
                  placeholder="32"
                />
              </b-form-group>
            </b-col>

            <!-- 包裹类型 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="包裹类型"
                label-for="type"
              >
                <b-form-input
                  id="type"
                  type="number"
                  placeholder="1"
                />
              </b-form-group>
            </b-col>

            <!-- 属性 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="属性"
                label-for="attribute"
              >
                <b-form-input
                  id="attribute"
                  value="32"
                />
              </b-form-group>
            </b-col>

            <!-- 长 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="长(cm)"
                label-for="length"
              >
                <b-form-input
                  id="length"
                  v-model="item.length"
                  value="32"
                  @blur="handleInteger(index,'length')"
                />
              </b-form-group>
            </b-col>

            <!-- 宽 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="宽(cm)"
                label-for="width"
              >
                <b-form-input
                  id="width"
                  v-model="item.width"
                  value="32"
                  @blur="handleInteger(index,'width')"
                />
              </b-form-group>
            </b-col>

            <!-- 高 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="高(cm)"
                label-for="height"
              >
                <b-form-input
                  id="height"
                  v-model="item.height"
                  value="32"
                  @blur="handleInteger(index,'height')"
                />
              </b-form-group>
            </b-col>

            <!-- 重量 -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
            >
              <b-form-group
                label="重量"
                label-for="weight"
              >
                <b-form-input
                  id="weight"
                  v-model="item.weight"
                  value="32"
                  @blur="handleInteger(index,'weight')"
                />
              </b-form-group>
            </b-col>

            <!-- 物流公司 -->
            <b-col
              xl="2"
              lg="2"
              md="2"
              sm="12"
            >
              <b-form-group
                label="物流公司"
                label-for="logistics"
              >
                <b-form-input
                  id="logistics"
                  value="32"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              xl="1"
              lg="1"
              md="1"
              sm="12"
              class="mb-50"
            >
              <b-button
                v-if="items.length > 1"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="btn-icon"
                style="margin-top:27px;"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="DeleteIcon"
                />
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

        </b-form>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
        length: 10,
        width: 10,
        height: 10,
        weight: 10,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    handleInteger(index, type) {
      this.items[index][type] = Math.abs(Math.ceil(this.items[index][type]))
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
